import './app.scss';
import { InitialMenuScene } from './Scenes/InitialMenuScene/InitialMenuScene';
import { useContext } from "react";
import { GameContext } from "./contexts/GameContext";
import { GameOver } from "./Scenes/GameOverScene/GameOver";
import memeleader from './solanaassets/memeleader.png'
import useSound from 'use-sound';
import futureman from './solanaassets/futuretrek.png'
import supplyrock from './solanaassets/dwaynerock.png'
import { SocialIcon } from 'react-social-icons'
import { TypeAnimation } from 'react-type-animation';
import {
  GrGamepad
} from 'react-icons/gr'
import { PiSpeakerNoneFill } from "react-icons/pi";
import { useRef } from 'react';

import CustomButton from './ui/CustomButton'
import Main from './ui/Main'

import ReactGA from "react-ga4";

const TRACKING_ID = "G-ZXCQETDMHV";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

function App() {
  const gameRef = useRef(null)
  const { gameState } = useContext(GameContext);
  const [playSound] = useSound('therocksong.mp3');


  window.addEventListener('keydown', function (e) {
    if (e.keyCode === 32 && e.target === document.body) {
      e.preventDefault();
    }
  });



  const executeScroll = () => gameRef.current.scrollIntoView()

  return (
    <div className="App" >
      <div className="font-body w-full max-w-screen-xl mx-auto flex flex-col h-screen justify-between scroll-smooth snap-y snap-mandatory" >
        <Main />
        <div className="w-full px-2 mt-16">
          <p className="font-bold cursor-pointer text-center text-5xl text-secondary epilepsy" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
            What is $TROK?
          </p>
          <p className="mt-8 text-3xl text-center text-[#ffffff]" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
            Do you smell what The Rock is cooking?
          </p>
          <div className='w-full text-center mx-auto'>
            <TypeAnimation
              sequence={[
                // Same substring at the start will only be typed out once, initially
                'innovation',
                1000, // wait 1s before replacing "Mice" with "Hamsters"
                'TPS asf',
                1000,
                'new boundaries',
                1000,
                'finance king',
                1000,
                'Oh Hooray',
                1000,
                "It Doesn't Matter What Your Name Is",
                2000,
              ]}
              wrapper="span"
              speed={50}
              className='pt-4 mx-auto text-center'
              style={{ fontSize: '2em', display: 'inline-block', color: '#74ee15', textAlign: 'center', textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}
              repeat={Infinity}
            />
          </div>
          <CustomButton className="hidden md:flex mt-8 px-4 py-2 hover:bg-secondary hover:bg-secondary hover:scale-105 hover:transition hover:ease-out duration-300 mx-auto" onClick={executeScroll}>
            <GrGamepad className="mr-2 -ml-1 w-4 h-4" />
            Click for special
          </CustomButton>
          <img className='pt-5 ' src={memeleader} alt='memeking' />
        </div>
        <div className="w-full px-2 mt-16">
          <p className="font-bold cursor-pointer text-center text-5xl text-secondary" style={{ color: '#ffe700', textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
            Meme king in Degen Chain
          </p>
          <p className="mt-8 text-3xl text-center text-[#ffffff] epilepsy" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
            WHY WE FIGHTIN
          </p>
          <div className="pt-4 sm:pt-24 pb-8 px-6 flex flex-col sm:flex-row sm:space-x-16 space-y-16 sm:space-y-0">
            <div className="mt-2 sm:mt-10  bg-contain bg-no-repeat sm:w-full sm:h-auto hover:scale-105 hover:rotate-[-.3deg] hover:transition hover:ease-out duration-300">
              <img src={futureman} alt="TheRockSolana" />

            </div>
            <div className="w-full pt-6 px-2 text-center sm:text-left" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
              <p className="font-bold cursor-pointer text-5xl text-secondary">
                - One needs to rule them all
              </p>
              <p className="font-bold cursor-pointer  text-5xl text-secondary">
                - Strong asfk
              </p>
              <p className="font-bold cursor-pointer text-5xl text-secondary">
                - Not just a meme, bringin utility wif it
              </p>

              <p className="font-bold cursor-pointer text-5xl text-secondary">
                - First of it's kind that will build on the ecosystem
              </p><br />
              <p className="font-bold cursor-pointer  text-5xl text-secondary epilepsy">
                - friens, do you smell what The Rock is cooking?
              </p>
              <p className="mt-8 text-3xl  text-[#ffffff]">
                buckle up frien
              </p>
              <div className="mt-8 flex justify-center space-x-4 mx-auto font-bold whitespace-nowrap">
              </div>
            </div>
          </div>
        </div>

        <div className="w-full px-2 mt-16" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
          <p className="font-bold cursor-pointer text-center text-5xl text-secondary epilepsy">
            chat wif us
          </p>
          <div className='pt-6 w-full items-center text-center space-x-6'>
            <SocialIcon url="https://x.com/TheRockSolana" />
            <SocialIcon url="https://t.me/therocksolana" />
          </div>
        </div>

        <div className="w-full px-2 mt-16" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
          <p className="font-bold cursor-pointer text-center text-5xl text-secondary" style={{ color: '#ffe700' }}>
            We splittin'
          </p>
          <p className="mt-8 text-3xl text-center text-[#ffffff]">
            not wif enemyfs
          </p>
          <div className="pt-24 pb-8 px-6 flex flex-col sm:flex-row sm:space-x-16 space-y-16 sm:space-y-0">
            <div className="w-full px-2">
              <p className="font-bold cursor-pointer text-center sm:text-left text-5xl text-secondary" style={{ color: '#f000ff' }}>
                Total Supply: 69000000420 $TROK
              </p>
              <p className="font-bold cursor-pointer text-center sm:text-left text-5xl text-secondary">
                - 50% Pre-Sale
              </p>
              <p className="font-bold cursor-pointer text-center sm:text-left text-5xl text-secondary">
                - 20% Community Fund - Unlocks in 6 months
              </p>
              <p className="font-bold cursor-pointer text-center sm:text-left text-5xl text-secondary">
                - 30% Liquidity
              </p>

              <p className="font-bold cursor-pointer text-center sm:text-left text-5xl text-secondary" style={{ color: '#ffe700' }}>
                - First of it's kind that will build on the ecosystem
              </p>
              <p className="mt-8 text-3xl text-center sm:text-left text-[#ffffff]">
                ONE TO RULE THEM ALL
              </p>
              <div className="mt-8 flex justify-center space-x-4 mx-auto font-bold whitespace-nowrap">
              </div>
            </div>
            <div className="mt-10  bg-contain bg-no-repeat sm:w-full sm:h-auto hover:scale-105 hover:rotate-[-.3deg] hover:transition hover:ease-out duration-300">
              <img className="facemove" src={supplyrock} alt="TheRockSolana" />

            </div>

          </div>
        </div>

        <div className="w-full px-2 mt-16" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
          <p className="font-bold cursor-pointer text-center text-5xl text-secondary epilepsy">
            And one last thing
          </p>
          <p className="mt-8 text-3xl text-center text-[#ffffff]">
            A deep message to enemyfs
          </p>
          <div className=' text-center pb-10 hidden md:flex' style={{ margin: '0 auto', textAlign: 'center' }}>
            <CustomButton className="mt-8 px-4 py-2 hover:bg-secondary hover:bg-secondary hover:scale-105 hover:transition hover:ease-out duration-300 mx-auto" onClick={playSound}>
              <PiSpeakerNoneFill className="mr-2 -ml-1 w-4 h-4" />
              Click to listen carefully
            </CustomButton>
          </div>
        </div>

        <div className="w-full px-2 mt-16" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
          <p className="font-bold cursor-pointer text-center text-6xl text-secondary" style={{ color: '#f000ff' }}>
            Raid it boyz
          </p>
          <p className="mt-4 text-3xl text-center text-[#ffffff] pb-4" >
            Help gettin' enemyz down with meme generator
          </p>
          <img className='pt-2' src={futureman} alt="TheRockSolana" style={{ margin: '0 auto' }} />
          <p className="mt-4 text-3xl text-center text-[#ffffff] " style={{ color: '#f000ff' }}>
            Caption:
          </p>
          <p className="mt-1 text-3xl text-center text-[#ffffff] pb-4">
            COMING SOON.
          </p>
{/*           <div className='w-full text-center pb-10 hidden md:flex' >
            <div className=' flex flex-row items-center mx-auto' style={{ margin: '0 auto', textAlign: 'center' }}>
              <div className='p-4 m-2'>
                <CustomButton className="flex flex-col items-center mt-2  py-2 hover:bg-secondary hover:bg-secondary hover:scale-105 hover:transition hover:ease-out duration-300 mx-auto" onClick={playSound}>
                  Shuffle Next Meme
                </CustomButton>
              </div>
              <div className='p-4 m-2'>
                <CustomButton className="flex flex-col items-center mt-2  py-2 hover:bg-secondary hover:bg-secondary hover:scale-105 hover:transition hover:ease-out duration-300 mx-auto" onClick={playSound}>
                  Shuffle Next Caption
                </CustomButton>
              </div>
            </div>
          </div> */}
        </div>


        <div ref={gameRef} className="flex-grow sm:text-l md:text-3xl text-secondary p-4 text-center font-bold pt-30 pb-40 hidden md:block" id="rockegg">
          <p className="pt-16 font-bold cursor-pointer text-center text-5xl text-secondary pb-10 epilepsy" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
            KILL YO ENEMYFS
          </p>
          <section className="pt-4 px-4 sm:-mb-16 sm:mt-4 center mx-auto" style={{ margin: '0 auto' }}>
            {gameState.gameOver ?
              <GameOver />
              :
              <InitialMenuScene />

            }
          </section>
        </div>
        <p className="pt-16 font-bold cursor-pointer text-center  text-2xl sm:text-3xl text-secondary pb-10 epilepsy md:hidden" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
          EASTER EGG IF U ACESS THRU PC
        </p>
      </div>
    </div>

  );
}

export default App;
