import style from './InitialMenuScene.module.scss';
import { SpaceShipSelector } from "../../Components/SpaceShipSelector/SpaceShipSelector";
import { MainScene } from '../MainScene/MainScene';
import { GameContext } from '../../contexts/GameContext';
import { useContext, useEffect, useState } from 'react';
import { TutorialScene } from '../TutorialScene/TutorialScene';

export const InitialMenuScene = () => {
  const { gameState, gameDispatch } = useContext(GameContext);
  const [renderMenu, setRenderMenu] = useState(gameState.initial);
  const [showGame, setshowGame] = useState(false);

  const handleClick = () => {
    gameDispatch({ type: "ON" });
    setshowGame(true)

  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRenderMenu(gameState.initial);
    }, gameState.initial ? 0 : 2000);

    return () => clearTimeout(timeout);
  }, [gameState.initial]);

  return (
    <div className={`${style['container']}`}>

      {
        renderMenu ?
          <div className={`${style['menu-screen']}`} style={{ top: gameState.initial ? 0 : "-100vh" }}>
            <h1 className={`${style['title']} pt-4`}>choose yo frien</h1>
            <SpaceShipSelector />
            <div style={{ width: "100%", textAlign: "center", paddingBottom: 16 }}>
              <button className={`${style['start-button']}`} onClick={handleClick} >GO GO GO!</button>
            </div>
          </div>
          : <TutorialScene />
      }
      
      
      <div className="w-full" style={{ top: gameState.initial ? 0 : 0, display: showGame ? 'block' : 'none' }}>
        <MainScene />
      </div>
    </div>
  )
}
