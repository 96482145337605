export const initialData = {
  points: 0,
  health: 100,
  level: 0,
  gameOver: false,
  spaceShipId: 0,
  fps: 120,
  paused: true,
  initial: true,
}
