import {
  GrGamepad
} from 'react-icons/gr'

// custom imports
import CustomButton from './CustomButton'
import logocoin from './../solanaassets/therocksolana.png'
import { PiSpeakerNoneFill } from "react-icons/pi";
import useSound from 'use-sound';
import { TypeAnimation } from 'react-type-animation';

const Main = () => {


  const [playSound] = useSound('therocksong.mp3');
  return (
    <main className="w-full flex-grow sm:text-l md:text-xl  text-secondary text-center font-bold" >
      <section className="px-4 sm:-mb-16 sm:mt-4 center">
        <div className="pt2 sm:pt-24 pb-8 px-6 flex flex-col sm:flex-row sm:space-x-16 space-y-16 sm:space-y-0">
          <div className="mt-1 sm:mt-10  bg-contain bg-no-repeat sm:w-full sm:h-auto hover:scale-105 hover:rotate-[-.3deg] hover:transition hover:ease-out duration-300" style={{ background: { logocoin } }}>
            <img className="facemove" src={logocoin} alt="TheRockSolana $TROK" />

          </div>
          <div className="w-full mx-auto" >
            <p className="font-bold cursor-pointer text-center text-5xl text-secondary " style={{ color: '#4deeea',textShadow:'2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }} >
              TheRockSolana
            </p>
            <p className="mt-8 text-3xl text-center justify-center text-[#ffffff]" style={{
              textShadow:'2px 0 black, 0 2px black, 4px 0 black, 0 -4px black',
            }}>
              It Doesn't Matter What Your Name Is.<br />
              We are here, end wif enemyfs<br />
              One to rule them all.
            </p>
            <p className="mt-8 text-3xl text-center text-[#ffffff]" style={{
              textShadow:'2px 0 black, 0 2px black, 4px 0 black, 0 -4px black'}}>
              No presale or launch yet, always beware of scams.<br />
              Check always on our official X/Twitter<br /><a href="https://twitter.com/TheRockSolana" target='_blank'><span style={{ color: '#f000ff' }}> @TheRockSolana </span></a>
            </p>
            <div className='w-full text-center mx-auto pt-4' style={{ textAlign: 'center', margin: '0 auto' }}>
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  'The king',
                  1000, // wait 1s before replacing "Mice" with "Hamsters"
                  'The unique',
                  1000,
                  'The master',
                  1000,
                  'The Rock',
                  3000
                ]}
                wrapper="span"
                speed={50}
                className='w-full pt-4 text-center mx-auto'
                style={{ fontSize: '3em', display: 'inline-block', textAlign: 'center', color: '#ffe700', margin: '0 auto',
                textShadow:'2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}
                repeat={Infinity}
              />
            </div>
            <div className="hidden md:flex mt-8 flex justify-center space-x-4 mx-auto font-bold whitespace-nowrap">
              <CustomButton className="px-4 py-2 hover:bg-secondary hover:bg-secondary hover:scale-105 hover:transition hover:ease-out duration-300" id='playButton' onClick={playSound}>
                <PiSpeakerNoneFill className="mr-2 -ml-1 w-4 h-4" />
                Click to listen the special message
              </CustomButton>
            </div>
            <div className="mt-8 flex justify-center space-x-4 mx-auto font-bold whitespace-nowrap">
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Main
